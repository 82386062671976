import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/seo";
import { BrowserView } from "react-device-detect";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import {
  MDXLayoutComponents,
  MDXGlobalComponents,
} from "../../components/mdx/";
import { PageHeader, Typography } from "antd";
import InfoDrawer from "../../components/ui/InfoDrawer";
import EmbeddedControls from "../../components/ui/EmbeddedControls";

const { Title, Text } = Typography;

export default function PageTemplate({ data: { mdx } }) {
  const textSubtitle =
    mdx.frontmatter.language === "nl" ? "Voorbeeld door " : "Examples by ";

  return (
    <div
      style={{
        backgroundColor: "#f1f3f5",
        minHeight: "100vh",
        paddingTop: "67px",
        position: "relative",
      }}
    >
      <Seo
        description={`This is an interactive chart made for ${mdx.frontmatter.client}. This chart is made with inFlow interactive charts (www.inflowchart.com). At inFlow we help professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools.`}
        title={`${mdx.frontmatter.title} by ${mdx.frontmatter.client}`}
      />
      <PageHeader
        style={{
          backgroundColor: "#fff",
          borderBottom: "1px solid #ced4da",
          height: "67px",
          left: "0",
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "15000",
        }}
        title={
          <Title
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              marginTop: "6px",
            }}
          >
            {mdx.frontmatter.title}
          </Title>
        }
        subTitle={
          <>
            <BrowserView style={{ position: "relative", top: "6px" }}>
              <Text type="secondary" className="hide-for-small">
                {textSubtitle}{" "}
              </Text>
              <a
                className="hide-for-small"
                href="https://www.inflowchart.com?utm=examples"
                rel="noreferrer noopener"
                target="_blank"
              >
                inFlow interactive charts
              </a>
            </BrowserView>
          </>
        }
        extra={[
          <EmbeddedControls slug={mdx.frontmatter.slug} />,
          <InfoDrawer
            added={mdx.frontmatter.added}
            client={mdx.frontmatter.client}
            edit={mdx.frontmatter.edit}
            language={mdx.frontmatter.language}
            shorturl={mdx.frontmatter.shorturl}
            source={mdx.frontmatter.source}
          />,
        ]}
      />
      <MDXProvider
        components={{
          ...MDXLayoutComponents,
          ...MDXGlobalComponents,
        }}
      >
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <div data-iframe-height></div>
      <script
        type="text/javascript"
        src="/js/iframeresizer/4.2.9/iframeResizer.contentWindow.min.js"
      ></script>
    </div>
  );
}

export const pageQuery = graphql`
  query LayoutExamplesQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        added
        client
        edit
        language
        shorturl
        slug
        source
        title
      }
    }
  }
`;
